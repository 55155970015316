import axiosIns from '@/libs/axios'
import store from '@/store'

export default class ProfileApis {
  async get() {
    return new Promise((resolve, reject) => {
      axiosIns.get('auth/profile').then(
        res => {
          store.commit('SET_USER_INFO', {
            profile_complete: res.data.results.profile_complete,
            is_deposited: res.data.results.is_deposited,
            is_legal: res.data.results.user.is_legal,
            mobile_is_verify: res.data.results.mobile_is_verify,
            document: res.data.results.document,
            id: res.data.results.user.id,
            email: res.data.results.user.email,
            first_name: res.data.results.user.first_name,
            last_name: res.data.results.user.last_name,
            isActive: res.data.results.user.isActive,
            edit_profile: res.data.results.edit_profile,
            mobile_country_code: res.data.results.user.mobile_country_code,
            // mobile_country_code: res.data.results.user.mobile_country_code ? res.data.results.user.mobile_country_code : '',
            mobile: res.data.results.user.mobile,
            phone: res.data.results.user.phone,
            phone_country_code: res.data.results.user.phone_country_code,
            city: res.data.results.user.city,
            twoFactorAuth: res.data.results.user.twoFactorAuth ? res.data.results.user.twoFactorAuth : {},
            citizenship: res.data.results.user.citizenship,
            residency: res.data.results.user.residency,
            notification: res.data.results.notification,
            profile: res.data.results.user.profile ? {
              gender: res.data.results.user.profile.gender,
              experience: res.data.results.user.profile.experience,
              hear: res.data.results.user.profile.hear,
              profession: res.data.results.user.profile.profession,
              trade_style: res.data.results.user.profile.trade_style,
              trade_asset: res.data.results.user.profile.trade_asset,
              birth_date: res.data.results.user.profile.birth_date,
              pin_code: res.data.results.user.profile.pin_code,
              hear_desc: res.data.results.user.profile.hear_desc,
            } : {},
          })
          store.commit('SET_USER', res.data.results.user)
          return resolve(res)
        },
      ).catch(err => { reject(err) })
    })
  }

  async saveGeneral(data) {
    return await axiosIns.post('auth/profile/general/save', data)
  }

  async storeChangePassword(data) {
    return axiosIns.post('auth/profile/security/save', data)
  }

  async storeFactor(data) {
    return axiosIns.post('auth/two-f-a/save', data)
  }

  async sendEmail() {
    return await axiosIns.post('auth/pin-code/reset')
  }

  async sendSMS(data) {
    return await axiosIns.post('auth/activation-mobile', data)
  }

  async sendActivationMail() {
    return await axiosIns.post('auth/active-user')
  }

  async reSendActivationMail(data) {
    return await axiosIns.post('resend-activation', data)
  }

  async submitPin(data) {
    return await axiosIns.post('auth/pin-code/change', data)
  }

  async saveNotification(data) {
    return await axiosIns.post('auth/configuration', data)
  }

  async storeForeign(data) {
    return await axiosIns.post('auth/profile/foreign/save', data)
  }

  acceptMobile = async data => await axiosIns.post('auth/send/code', data)

  verifyMobile = async data => await axiosIns.post('auth/verify/code/mobile', data)
}
