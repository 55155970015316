export default [
  {
    path: '/:lang/document/:tab?',
    name: 'document',
    component: () => import('@/views/documents/Document.vue'),
    meta: {
      pageTitle: 'Document',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Document',
          active: true,
        },
      ],
    },
  },
]
