export default [
  {
    path: '/:lang/profile/:tab?',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: 'set-pin',
    name: 'set-pin',
    component: () => import('@/views/profile/SetPin.vue'),
    meta: {
      layout: 'full',
      middleware: 'auth',
      pageTitle: 'SetPin',
      breadcrumb: [
        {
          text: 'SetPin',
          active: true,
        },
      ],
    },
  },
  {
    path: 'token-expired',
    name: 'token-expired',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
]
