import CustomerWalletApis from '@/services/apis/customer-wallet'

const customerWallet = new CustomerWalletApis()
export default {
  state: {
    depositHistory: [],
  },
  getters: {
    depositHistory: state => state.depositHistory,
  },
  mutations: {
    SET_DEPOSIT_HISTORY(state, depositHistory) {
      state.depositHistory = depositHistory
    },
  },
  actions: {
    async getDepositHistoryInfo({ commit }) {
      const response = await customerWallet.getDepositHistoryInfo()
      commit('SET_DEPOSIT_HISTORY', response.data.depositHistory)
    },
  },
}
