export default [
  {
    path: '/:lang/deposit-withdraw-wall',
    name: 'deposit-withdraw-wall',
    component: () => import('@/views/customer-wallets/DepositWithdrawWall.vue'),
    meta: {
      pageTitle: 'Deposit Withdraw Wall',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Deposit Withdraw Wall',
          active: true,
        },
      ],
    },
  },
]
