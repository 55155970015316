class OrderHistorySocket {
     socket

     _instance

     onMessageFunctions=[]

     onError

     onOpen

    vueStore

    pingInterval

    eventListeners=[]

    constructor() {
      // eslint-disable-next-line no-underscore-dangle
      if (OrderHistorySocket._instance) {
        throw new Error("Singleton classes can't be instantiated more than once.")
      }
      // eslint-disable-next-line no-underscore-dangle
      OrderHistorySocket._instance = this
    }

    addEventListener(identifire, event, callback) {
      if (this.socket) {
        this.socket.addEventListener(event, callback)
      }
      this.eventListeners.push({ identifire, event, callback })
    }

    removeEventListener(identifire, event, callback) {
      if (this.socket) {
        this.socket.removeEventListener(event, callback)
      }
      // eslint-disable-next-line no-return-assign, no-param-reassign, eqeqeq
      this.eventListeners = this.eventListeners.filter(listener => listener.identifire != identifire && (event ? listener.event = event : true))
    }

    /**
     * get instance of websocket connection
     */
    async getConnection() {
      return await this.connect().then(() => this.socket)
    }

    setVueStore(store) {
      this.vueStore = store
    }

    /**
     *  connect to websocket if not connected
     * @returns Promise to connect to websocket
     */
    connect() {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        if (!token) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('No token')
          return
        }
        // eslint-disable-next-line eqeqeq
        if (this.vueStore.state.auth.user?.isActive != '1') {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('user profile is not completed')
          return
        }
        // eslint-disable-next-line eqeqeq
        if ((token && !this.socket) || (token && this.socket && this.socket.readyState == WebSocket.CLOSED)) {
          this.socket = new WebSocket(
            `${process.env.VUE_APP_ORDER_HISTORY_WEBSOCKET_URL
            }?access_token=${token}`,
          )
          this.socket.addEventListener('open', event => {
            this.pingInterval = setInterval(() => {
              if (this.socket) {
                this.socket.send(JSON.stringify({ event: 'ping' }))
              }
            }, 10000)
          })
          this.socket.onclose = () => {
            setTimeout(() => {
              console.log('on close order history websocket reconnecting ...')
              if (this.socket) {
                this.socket.close()
              }
              this.connect()
            // eslint-disable-next-line radix
            }, process.env.VUE_APP_WEBSOCKET_CONNECT_RETRY_PERIOD ? parseInt(process.env.VUE_APP_WEBSOCKET_CONNECT_RETRY_PERIOD) : 5000)
            clearInterval(this.pingInterval)
          }
          this.socket.addEventListener('error', () => {
            // setTimeout(() => {
            //     console.log('order history websocket reconnecting ...');
            //     if (this.socket) {
            //         this.socket.close();
            //     }
            //     this.connect()
            // }, process.env.WEBSOCKET_CONNECT_RETRY_PERIOD ? parseInt(process.env.WEBSOCKET_CONNECT_RETRY_PERIOD) : 5000);
            // eslint-disable-next-line prefer-promise-reject-errors
            clearInterval(this.pingInterval)
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(true)
          })

          // set event listeners after connect
          this.eventListeners.map(listener => {
            if (this.socket) {
              this.socket.addEventListener(listener.event, listener.callback)
            }
          })

          this.onMessage()
        }
        // eslint-disable-next-line eqeqeq
        if (this.socket && this.socket.readyState == 1) {
          // this.onMessage()
          resolve(true)
        }
        // eslint-disable-next-line eqeqeq
        if (this.socket && this.socket.readyState == 0) {
          const statusInterval = setInterval(() => {
            // eslint-disable-next-line eqeqeq
            if (this.socket?.readyState == 1) {
              resolve(true)
              clearInterval(statusInterval)
            }
          }, 200)
        }
      })
    }

    close() {
      // eslint-disable-next-line eqeqeq
      if (this.socket && this.socket.readyState == 1) {
        this.socket.close()
        console.log('order websocket closed')
      }
    }

    /**
     * function to add event listener to websocket
     * @param key just a name for the event
     * @param event event name from the websocket
     * @param callback  function to call when the event is received
     */
    setMessageEvent(key, event, callback) {
      // check if key dosent exist on onMessageFunctions
      const isExist = this.onMessageFunctions.find(
        // eslint-disable-next-line eqeqeq
        item => item.key == key,
      )
      if (!isExist) {
        this.onMessageFunctions.push({ key, event, callback })
      } else {
        throw new Error(`Key already exist ${isExist.key}`)
        // this.removeMessageEvent(key)
        // this.onMessageFunctions.push({ key, event, callback })
      }
    }

    /**
     *  function to remove event listener to websocket
     * @param key key to remove from event listener function
     */
    removeMessageEvent(key) {
      this.onMessageFunctions = this.onMessageFunctions.filter(
        // eslint-disable-next-line eqeqeq
        item => item.key != key,
      )
    }

    onMessage() {
      if (this.socket) {
        this.socket.onmessage = event => {
          const message = JSON.parse(event.data)
          if (message.data) {
            if (message.data.error) {
              if (this.onError) {
                this.onError(message.data.error)
              }
              // eslint-disable-next-line eqeqeq
              if (message.data.error.code == 401) {
                console.log(message.data.error)
              }
            } else {
              this.onMessageFunctions.map(
              // eslint-disable-next-line array-callback-return
                item => {
                // eslint-disable-next-line eqeqeq
                  if (item.event == message.event) {
                    item.callback(message.data)
                  }
                },
              )
            }
          }
        }
      }
    }

    /**
     *  function to send message to websocket
     * @param event event name to send to websocket
     * @param data  data to send to websocket
     */
    async send(event, data) {
      return new Promise((resolve, reject) => {
        this.connect().then(() => {
          if (this.socket) {
            this.socket.send(JSON.stringify({ event, data }))
            resolve(true)
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
}
export default new OrderHistorySocket()
