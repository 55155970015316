export default [
  {
    path: '/:lang/customer-wallets',
    name: 'customer-wallet',
    component: () => import('@/views/customer-wallets/CustomerWallet.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'customer-wallet',
      pageTitle: 'Customer Wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Customer Wallet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/crypto-wallets',
    name: 'crypto-wallets',
    component: () => import('@/views/components/wallet/CryptoWallets.vue'),
    meta: {
      navActiveLink: 'Crypto',
      route: 'customer-wallet',
      pageTitle: 'Crypto Wallets',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/deposit',
    name: 'deposit',
    component: () => import('@/views/customer-wallets/fiat/Deposit.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'deposit',
      pageTitle: 'deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/withdraw',
    name: 'withdraw',
    component: () => import('@/views/customer-wallets/fiat/Withdraw.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      route: 'withdraw',
      pageTitle: 'withdrawal',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Withdraw',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/deposit',
    name: 'fiat-deposit',
    component: () => import('@/views/customer-wallets/fiat/FiatDeposit.vue'),
    meta: {
      navActiveLink: 'deposit',
      route: 'fiat-deposit',
      pageTitle: 'fiat_Deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'deposit',
          active: false,
          to: {
            name: 'deposit',
          },

        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/withdraw',
    name: 'fiat-withdraw',
    component: () => import('@/views/customer-wallets/fiat/FiatWithdraw.vue'),
    meta: {
      navActiveLink: 'withdraw',
      pageTitle: 'fiat_withdraw',
      route: 'fiat-withdraw',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'withdraw-pageTitle',
          active: false,
          to: {
            name: 'withdraw',
          },
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/history/:currency',
    name: 'history',
    component: () => import('@/views/customer-wallets/fiat/FiatHistory.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      pageTitle: 'Customer Wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'History',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/fiat/Invoice',
    name: 'Invoice',
    component: () => import('@/views/customer-wallets/fiat/FiatInvoice.vue'),
    meta: {
      navActiveLink: 'customer-wallet',
      pageTitle: 'Customer Wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/crypto-deposit/:coin_name/:coin_symbol/:network_name',
    name: 'cryptoDeposit',
    component: () => import('@/views/customer-wallets/cryptoDeposit.vue'),
    meta: {
      navActiveLink: 'crypto-wallets',
      pageTitle: 'Crypto Deposit',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: false,
          to: {
            name: 'crypto-wallets',
          },
        },
        {
          text: 'Deposit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/crypto-withdraw/:coin_name/:coin_symbol/:network_name',
    name: 'cryptoWithdraw',
    component: () => import('@/views/customer-wallets/cryptoWithdraw.vue'),
    meta: {
      navActiveLink: 'crypto-wallets',
      pageTitle: 'Crypto Withdraw',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: false,
          to: {
            name: 'crypto-wallets',
          },
        },
        {
          text: 'Withdraw',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/customer-wallets/CreateCryptoWallet',
    name: 'create_new_wallet',
    component: () => import('@/views/components/wallet/CreateCryptoWallet.vue'),
    meta: {
      navActiveLink: 'crypto-wallets',
      pageTitle: 'create_new_wallet',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Crypto Wallets',
          active: false,
          to: {
            name: 'crypto-wallets',
          },
        },
        {
          text: 'create_new_wallet',
          active: true,
        },
      ],
    },
  },
]
