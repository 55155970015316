export default [
  {
    path: '/:lang/transaction-history',
    name: 'transaction-history',
    component: () => import('@/views/customer-wallets/TransactionHistory.vue'),
    meta: {
      pageTitle: 'Transaction History',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Transaction History',
          active: true,
        },
      ],
    },
  },
]
