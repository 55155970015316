// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import ErrorService from '@/services/ErrorService'

const showErrorMessages = {
  data() {
    return {}
  },
  methods: {
    showError(errors) {
      let textError = ''
      Object.entries(errors).forEach(([key, value]) => {
        Object.entries(value).forEach(([key2, value2]) => {
          textError += `${key}:${value2} \n\n`
        })
      })
      return textError
    },
    showReaction(response) {
      // ErrorService.onError(response)
      if (response.status === 429) {
        this.toast('Too Many requests', 'danger')
      } else if (response.status === 201 || response.status === 200) {
        this.toast('success', 'success')
      } else if (response.status === 422) {
        const textError = this.showError(response.data.results.errors)
        this.toast(textError, 'danger')
      } else if (response.status === 403) {
        this.toast(response.data.message, 'danger')
      } else if (response.status === 500) {
        this.toast(response.statusText, 'danger')
      } else if (response.status === 419) {
        this.toast(response.data.message, 'danger')
      } else if (response.status === 401) {
        this.toast(response.statusText, 'You are logged out! login please first!')
      } else {
        this.toast('unknownError', 'danger')
      }
    },
    show200CustomLogin(response) {
      if (response.type === 'has Two Factor') {
        this.toast('You are logged in!', 'success')
        // this.$router.push(`/${this.$i18n.locale}/login-section2`)
        this.$router.push({ name: 'login-section2' })
      } else if (response.type === 'login') {
        this.toast('You are logged in!', 'success')
        this.$router.push({ name: 'profile' })
      } else {
        this.showReaction(response)
      }
    },
    toast(value, status) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: value,
          icon: 'EditIcon',
          variant: status,
        },
      })
    },
  },
}
export default showErrorMessages
export const _ = null
