import { setLang } from '@/auth/utils'
import i18n from '@/libs/i18n'
import router from '@/router'
import store from '@/store'
import ProfileApis from '@/services/apis/profile'

const { get } = new ProfileApis()

router.beforeEach(async (to, from, next) => {
  window.document.title = i18n.t(to.meta && to.meta.pageTitle ? to.meta.pageTitle : 'Home')
  if (to.name === 'logout') {
    localStorage.removeItem('authenticated')
    store.dispatch('logout')
  }
  const isAuthenticated = localStorage.getItem('authenticated')
  let language = to.params.lang
  const allowedLanguage = ['en', 'fa', 'cs', 'ar', 'cn', 'ru']

  if (!language || allowedLanguage.includes(language) === false) {
    language = 'en'
  }
  setLang(language)
  const token = localStorage.getItem('token')

  if (Object.keys(store.state.auth.user).length === 0 && to.meta.middleware !== 'guest' && token) {
    get()
      .then(res => {
        store.commit('SET_USER', res.data.results.user)
        store.commit('SET_AUTHENTICATED', true)
        store.commit('SET_TOKEN', localStorage.getItem('token'))
      }).catch(() => {
        next({ name: 'login', params: { lang: language }, replace: true })
      })
  }

  if (to.meta.middleware === 'auth' && !isAuthenticated) {
    next({
      name: 'login',
      params: { lang: i18n.locale },
      replace: true,
      query: { redirect: from.path },
    })
  } else if (to.meta.middleware === 'guest' && isAuthenticated) {
    next({ name: 'dashboard', params: { lang: i18n.locale }, replace: true })
  } else next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
