import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class DocumentApis {
    saveDocumentGeneral = async data => await axiosIns.post('auth/document/general/action', data)

    saveDocumentResidency= async data => await axiosIns.post('auth/document/residency/action', data)

    saveDocumentBankAccounts =async data => await axiosIns.post('auth/document/bank/action', data)

    saveDocumentLegal= async data => await axiosIns.post('auth/document/legal/action', data)

    getDocumentInfo = async () => await axiosIns.get('auth/document/general/all')

    getDocumentGeneralInfo = async () => await axiosIns.get('auth/document/general')

    getDocumentResidencyInfo = async () => await axiosIns.get('auth/document/residency')

    getDocumentBankInfo = async data => await axiosIns.get(`auth/document/bank${objectToUrlParam(data)}`)

    getDocumentLegalInfo = async () => await axiosIns.get('auth/document/legal')
}
