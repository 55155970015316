import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import profile from './profile'
import documents from './documents'
import EconomicCalendar from './economic-calendar'
import Deposit from './customer-wallet/deposit'
import Withdraw from './customer-wallet/withdraw'
import TransactionHistory from './transaction-history'
import Messaging from './messaging'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    profile,
    documents,
    EconomicCalendar,
    TransactionHistory,
    Deposit,
    Withdraw,
    Messaging,
  },
  strict: process.env.DEV,
})
