import TransactionHistoryApis from '@/services/apis/transaction-history'

const transactionHistory = new TransactionHistoryApis()
export default {
  state: {
    transactionHistory: [],
  },
  getters: {
    transactionHistory: state => state.transactionHistory,
  },
  mutations: {
    SET_TRANSACTION_HISTORY(state, transactionsHistory) {
      state.transactionHistory = transactionsHistory
    },
  },
  actions: {
    async getTransactionHistoryInfo({ commit }) {
      const response = await transactionHistory.getTransactionHistoryInfo()
      commit('SET_TRANSACTION_HISTORY', response.data.transactionHistory)
    },
  },
}
