export default [
  {
    path: '/:lang/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      middleware: 'guest',
      route: 'authentication',
    },
  },
  {
    path: 'login-section2',
    name: 'login-section2',
    component: () => import('@/views/auth/LoginSection2.vue'),
    meta: {
      layout: 'full',
      middleware: 'guest',
      route: 'authentication',
    },
  },
  {
    path: '/:lang/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      middleware: 'guest',
      route: 'authentication',
    },
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      middleware: 'guest',
      route: 'authentication',
    },
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      middleware: 'guest',
      route: 'authentication',
    },
  },
  {
    path: 'email-activation',
    name: 'email-activation',
    component: () => import('@/views/error/EmailActivation.vue'),
    meta: {
      middleware: 'guest',
      layout: 'full',
    },
  },
  {
    path: 'register-success',
    name: 'register-success',
    component: () => import('@/views/auth/RegisterSuccess.vue'),
    meta: {
      middleware: 'both',
      layout: 'full',
    },
  },
  {
    path: 'agreement-register',
    name: 'agreement-register',
    component: () => import('@/views/auth/AgreementRegister.vue'),
    meta: {
      middleware: 'both',
      layout: 'full',
    },
  },
]
