export default [
  {
    path: '/:lang/metatrader',
    name: 'metatrader',
    component: () => import('@/views/metatrader/Metatrader.vue'),
    meta: {
      pageTitle: 'Metatrader',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: true,
          to: {
            name: 'metatrader',
          },
        },
      ],
    },
  },

  {
    path: '/:lang/metatrader/history/:id',
    name: 'Metatrader_History',
    component: () => import('@/views/metatrader/History.vue'),
    meta: {
      pageTitle: 'Metatrader_History',
      middleware: 'auth',
      breadcrumb: [
        {
          text: 'Metatrader',
          active: false,
          to: {
            name: 'metatrader',
          },
        },
        {
          text: 'Metatrader_History',
          active: true,
          to: {
            name: 'metatrader_History',
          },
        },
      ],
    },
  },
]
