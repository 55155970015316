import axiosIns from '@/libs/axios'

export default class CustomerWalletApis {
  getDepositHistoryInfo = async () => await axiosIns.get('deposit')

  getCryptoWalletsInfo = async () => await axiosIns.get('customer-wallet')

  getWithdrawHistoryInfo = async () => await axiosIns.get('withdraw')

  storeWithdraw = async data => await axiosIns.post('auth/withdrawal-ticket-bank-module/store', data)

  getCryptoWallets = async () => await axiosIns.get('auth/node/communication/crypto/wallet')

  getWalletByAddress = async data => await axiosIns.get(`auth/node/communication/crypto/wallet/get/address?currency=${data.currency}&network=${data.network}`)

  getCurrencies = async () => await axiosIns.get('auth/node/communication/crypto/wallet/currencies')

  getOmsCoins = async () => await axiosIns.get('auth/omsmodule/coins')

  createWallet = async data => await axiosIns.post('auth/node/communication/crypto/wallet/address', data);

  withdrawalCryptoWallet = async data => await axiosIns.post('auth/node/communication/crypto/wallet/withdrawal', data);

  withdrawalSendCode = async data => await axiosIns.post('/auth/node/communication/crypto/wallet/otp/send', data);

  withdrawalResendCode = async data => await axiosIns.post('/auth/node/communication/crypto/wallet/otp/resend', data);

  getCoin = async coinId => await axiosIns.get(`auth/omsmodule/coin/information?coin_id=${coinId}`);

  getBalance = async () => await axiosIns.get('auth/omsmodule/account');

  //   lastTransactions = async page => await axiosIns.get(`/auth/node/communication/crypto/wallet/last/transaction?page=${page}`)

  lastTransactions = async data => await axiosIns.get(`auth/node/communication/crypto/wallet/last/transaction/deposit?page=${data.current_page}&per_page=${data.per_page}`)

  withdrawTransactions = async data => await axiosIns.get(`auth/node/communication/crypto/wallet/last/transaction/withdraw?page=${data.current_page}&per_page=${data.per_page}`)
}
