import CustomerWalletApis from '@/services/apis/customer-wallet'

const customerWallet = new CustomerWalletApis()
export default {
  state: {
    withdrawHistory: [],
  },
  getters: {
    withdrawHistory: state => state.withdrawHistory,
  },
  mutations: {
    SET_WITHDRAW_HISTORY(state, withdrawHistory) {
      state.withdrawHistory = withdrawHistory
    },
  },
  actions: {
    async getWithdrawHistoryInfo({ commit }) {
      const response = await customerWallet.getWithdrawHistoryInfo()
      commit('SET_WITHDRAW_HISTORY', response.data.withdrawHistory)
    },
  },
}
